import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Tobias Meyer | Web Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Personal Website of Tobias Meyer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is ',
  name: 'Tobias',
  subtitle: "I'm a self-taught Fullstack JavaScript Developer.",
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile_tobias.jpg',
  paragraphOne:
    'Software development is a passion I only discovered for myself a few years ago, but since then I have pursued it with such fervour that I became a senior developer in less than 2 years.',
  paragraphTwo:
    'Self-learning and the building of own ideas as code on countless evenings and weekends are the fuel this rapid journey. And there is no thought of slowing it down.',
  paragraphThree: 'The journey still continuous.',
  // resume: 'https://www.resumemaker.online/de.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'mongodb-crud.png',
    title: 'Function App CRUD Operations with MongoDB',
    info: 'CRUD REST API based on Azure Functions and MongoDB',
    info2: '',
    url: '',
    repo: 'https://github.com/tobim-dev/crud-mongodb-node-serverless', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Do you want to know more?',
  btn: "Let's Talk",
  email: 'mail@tmeyer.me',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/Toben2010',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tobiasmeyer90/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tobim-dev',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
